const logo = () => (
  <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 14">
    <g clipPath="url(#a)" fill="#fff">
      <path d="M49.127.833c0-.447.36-.809.808-.809h8.052v1.408a.808.808 0 0 1-.808.808h-8.053V.833Zm8.244 6.062a.808.808 0 0 1-.808.808h-7.438V6.296c0-.447.361-.809.808-.809h7.438v1.408Zm-.192 4.4c.446 0 .808.361.808.808v1.408h-8.052a.808.808 0 0 1-.809-.808v-1.408h8.053ZM28.008 7.325V.035h1.447c.447 0 .808.361.808.808v6.483c0 2.283 1.704 4.218 3.882 4.407l.242.021c.418.035.74.386.74.805v1.444H34.5c-3.578 0-6.49-2.995-6.49-6.675l-.002-.003ZM66.31 9.292l4.204 4.196h-2.705a.808.808 0 0 1-.57-.237l-4.666-4.658a.805.805 0 0 1 .57-1.378h2.38c1.388 0 2.542-1.1 2.574-2.455a2.491 2.491 0 0 0-.716-1.817 2.497 2.497 0 0 0-1.8-.76h-2.694a.808.808 0 0 1-.808-.808V.035h3.502c2.528 0 4.619 2.055 4.663 4.582a4.589 4.589 0 0 1-1.337 3.315 4.853 4.853 0 0 1-2.597 1.36ZM45.033 1.458a.808.808 0 0 1-.808.808h-7.93V.843c0-.447.361-.808.808-.808h7.93v1.423ZM39.55 5.68c0-.447.362-.809.808-.809h1.421v7.82a.808.808 0 0 1-.808.807h-1.42V5.68ZM5.161 13.552C2.315 13.497 0 11.102 0 8.212V.035h1.429c.446 0 .808.361.808.808v7.38c0 1.666 1.325 3.054 2.952 3.092a3.008 3.008 0 0 0 2.185-.861 3.003 3.003 0 0 0 .913-2.164V.843c0-.447.361-.808.808-.808h1.429V8.29a5.223 5.223 0 0 1-1.578 3.754 5.226 5.226 0 0 1-3.683 1.508h-.102ZM20.622.505a.808.808 0 0 0-.75-.505h-.824c-.33 0-.625.2-.749.505l-5.236 12.958h1.688c.44 0 .831-.263.996-.67L19.46 3.6l3.712 9.191c.165.409.557.671.996.671h1.688L20.62.505Z"></path>
      <path d="M20.688 9.714c-.44-.088-.78-.43-.868-.868l-.366-1.803-.366 1.803c-.088.44-.43.78-.867.868l-1.804.366 1.804.364c.439.089.779.43.867.868l.366 1.804.366-1.804c.089-.44.43-.78.868-.868l1.803-.364-1.803-.366ZM79.406 12.953l-.307-.84h-3.585l-.306.84a.828.828 0 0 1-.78.544h-1.295l2.898-7.493a.83.83 0 0 1 .773-.53h1.004c.343 0 .65.212.774.53l2.887 7.491h-1.284a.829.829 0 0 1-.78-.544v.002Zm-2.099-5.999-1.335 3.68h2.657l-1.323-3.68h.002ZM83.417 13.498V6.305a.83.83 0 0 1 .83-.83h.878v7.194a.83.83 0 0 1-.83.83h-.878Z"></path>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h85.127v14H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default logo;
