import { DeleteOutlined, EditOutlined, UndoOutlined } from "@ant-design/icons";
import React from "react";
import "./EditorActionPanel.css";

const EditorActionPanel: React.FC<{
  onDeleteAction: any;
  onEditAction: any;
  onUndoAction: any;
  showDelete?: boolean;
  showEdit?: boolean;
  showUndo?: boolean;
}> = ({
  onDeleteAction,
  onEditAction,
  onUndoAction,
  showDelete = true,
  showEdit = true,
  showUndo = false,
}) => {
  return (
    <>
      {showEdit && (
        <EditOutlined
          onClick={onEditAction}
          className="action-icon edit-action-icon"
        />
      )}
      {showDelete && (
        <DeleteOutlined
          onClick={onDeleteAction}
          className="action-icon delete-action-icon"
        />
      )}
      {showUndo && (
        <UndoOutlined
          onClick={onUndoAction}
          className="action-icon undo-action-icon"
        />
      )}
    </>
  );
};

export default EditorActionPanel;
