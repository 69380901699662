import { Drawer, Progress, Space } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import SnippetDrawer from "../Components/Preview/SnippetDrawer";
import Button from "../Components/Button";
import Loader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";

export const WidgetPreview: React.FC = () => {
  const { articleId } = useParams();
  const [visible, setVisible] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState<any>(null);
  const [isArticleReady, setIsArticleReady] = useState(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    setLoading(true);
    let intervalId: NodeJS.Timeout;
    const fetchArticleData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/items/${articleId}`
      );
      if (!response.ok) {
        setIsArticleReady(true);
        return;
      }
      try {
        const data = await response.json();
        setArticleData(data);
        if (data.porcentaje === 100) {
          setIsPolling(false);
          if (intervalId) {
            clearInterval(intervalId);
          }

          setIsArticleReady(true);
        } else {
          setIsPolling(true);
        }
      } catch (error) {
        setIsArticleReady(true);
      }
      setLoading(false);
    };

    fetchArticleData();

    if (isPolling) {
      intervalId = setInterval(() => {
        fetchArticleData();
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [articleId, isPolling]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => {
        if (iframe.contentWindow) {
          iframe.contentWindow.document.body.style.overflow = "hidden";
        }
      };
    }
  }, []);

  return (
    <>
      {loading && <Loader color="#1890ff" style={{ alignSelf: "center" }} />}
      {!loading && !isArticleReady && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ClipLoader speedMultiplier={0.5} color="#1890ff" size={150} />
          <Space size="large" />
          <Progress
            style={{ width: "50vh", marginTop: "10px" }}
            percent={articleData?.porcentaje || 1}
            strokeColor="#1890ff"
            size={10}
          />
          <h4 style={{ marginBottom: "40px", marginTop: "10px" }}>
            {articleData?.estado || ""}
          </h4>
          <NavLink to="/">
            <Button label="Volver al Menú" />
          </NavLink>
        </div>
      )}
      {!loading && isArticleReady && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "none",
              maxWidth: "600px",
              width: "90dvw",
              height: "100dvh",
              display: "flex",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html: `<ualter-sound data-content-id="${articleId}"></ualter-sound>`,
            }}
          ></div>
          <NavLink to={`/edit-modules/${articleId}`}>
            <Button label="Editar Módulos" />
          </NavLink>
          <NavLink to={`/edit-transcript/${articleId}`}>
            <Button label="Editar Transcripción" />
          </NavLink>
          <Button label="Código Widget" onClick={showDrawer} />
          <NavLink to="/">
            <Button label="Volver al Menú" />
          </NavLink>

          <Drawer
            title="Copia y pega el siguiente código para incluir el widget de UalterSound"
            placement="bottom"
            onClose={closeDrawer}
            open={visible}
            width={"80vh"}
            height={"80vh"}
          >
            <SnippetDrawer articleId={articleId || ""} />
          </Drawer>
        </div>
      )}
    </>
  );
};
