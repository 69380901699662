import React, { useState, ChangeEvent } from "react";
import "./Styles/NewAudio.css";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import SyncLoader from "react-spinners/SyncLoader";
import {
  Button,
  ConfigProvider,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Modal,
  Switch,
  Tooltip,
  UploadFile,
  UploadProps,
} from "antd";
import ButtonLgg from "../Components/Button";
import locale from "antd/locale/es_ES";
import { Select, Upload } from "antd/lib";

import dayjs from "dayjs";
import { InboxOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";

const NewAudio: React.FC = () => {
  const [nombre, setNombre] = useState<string>("");
  const [lugar, setLugar] = useState<string>("");
  const [fecha, setFecha] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [useCleanStep, setUseCleanStep] = useState<boolean>(false);
  const [audioLanguage, setAudioLanguage] = useState<string>("es");
  const [transcriptionLanguage, setTranscriptionLanguage] =
    useState<string>("es");
  const navigate = useNavigate();

  const handleNombreChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNombre(event.target.value);
  };

  const handleLugarChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLugar(event.target.value);
  };

  const handleFechaChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFecha(dayjs(date).format("YYYY-MM-DD"));
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    newFileList = newFileList.slice(-1);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      file.status = "done";
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
  };

  const readFileContent = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file as any); // Read file as ArrayBuffer
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const accessToken = await fetchAuthSession();
    const fileContent = await readFileContent(
      fileList[0].originFileObj as File
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "/new-audio",
        {
          nombre,
          lugar,
          fecha,
          originalFileName: fileList[0].name,
          contentType: fileList[0].type,
          useCleanStep,
          audioLanguage,
          transcriptionLanguage,
          fileExt: fileList[0].name.split(".").pop(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.tokens?.accessToken.toString()}`,
          },
        }
      )
      .then((response) => {
        const { url, key } = response.data;
        setFileList(fileList.map((file) => ({ ...file, status: "uploading" })));
        const articleId = key.split("/").pop().split(".")[0];
        axios
          .put(url, fileContent, {
            headers: {
              "Content-Type": fileList.at(0)?.type,
            },
          })
          .then((response) => {
            setFileList(fileList.map((file) => ({ ...file, status: "done" })));
            Modal.success({
              title: "Audio cargado",
              content:
                "El audio ha sido cargado exitosamente y está siendo procesado.",
              okButtonProps: {
                style: { backgroundColor: "#1c2c6d", color: "white" },
              },
              okText: "Aceptar",
              onOk: () => {
                navigate(`/preview/${articleId}`);
              },
            });
          })
          .catch((error) => {
            setFileList(fileList.map((file) => ({ ...file, status: "error" })));
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [form] = Form.useForm();

  return (
    <React.Fragment>
      <div className="formContainer">
        <Form
          className="newForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
          size="middle"
          disabled={loading}
        >
          <h3>Procesar nuevo audio</h3>
          <Form.Item label="Nombre del orador" name="nombre">
            <Input
              type="text"
              id="nombre"
              placeholder="Ingrese el nombre"
              value={nombre}
              onChange={handleNombreChange}
              required
            />
          </Form.Item>

          <Form.Item label="Lugar" name="lugar">
            <Input
              type="text"
              id="lugar"
              placeholder="Ingrese el lugar"
              value={lugar}
              onChange={handleLugarChange}
              required
            />
          </Form.Item>
          <Form.Item label="Fecha" name="Fecha">
            <ConfigProvider locale={locale}>
              <DatePicker
                maxDate={dayjs()}
                className="date-picker"
                onChange={handleFechaChange}
                required
                lang="es"
                placeholder="Seleccione una fecha"
                format={"DD/MM/YYYY"}
              />
            </ConfigProvider>
          </Form.Item>

          <Form.Item label="Idioma del audio" name="audio_language">
            <Select
              placeholder="Seleccione el idioma"
              id="audio_language_selector"
              value={audioLanguage}
              defaultValue={audioLanguage}
              onSelect={(value) => setAudioLanguage(value.toString())}
            >
              <Select.Option value="es">Español</Select.Option>
              <Select.Option value="en">Inglés</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Idioma de la transcripción"
            name="transcription_language"
          >
            <Select
              placeholder="Seleccione el idioma"
              id="transcription_language_selector"
              value={transcriptionLanguage}
              defaultValue={transcriptionLanguage}
              onSelect={(value) => setTranscriptionLanguage(value.toString())}
            >
              <Select.Option value="es">Español</Select.Option>
              <Select.Option value="en">Inglés</Select.Option>
            </Select>
          </Form.Item>

          <Tooltip title="Esta funcionalidad incrementa el tiempo de espera para publicar el contenido.">
            <Form.Item
              label="Habilitar la mejora de audio para entornos ruidosos"
              name="useCleanStep"
            >
              <Switch
                id="useCleanStep"
                defaultChecked={false}
                value={useCleanStep}
                onChange={(v) => setUseCleanStep(v)}
              />
            </Form.Item>
          </Tooltip>
          <Upload.Dragger
            name="file"
            id="file"
            accept=".mp3,.m4a,.wav,.ogg,.flac,.opus"
            multiple={false}
            maxCount={1}
            fileList={fileList}
            customRequest={() => {}}
            onChange={handleChange}
          >
            <p className="upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="upload-text">
              Haga clic aquí o arrastre el archivo que desea procesar
            </p>
          </Upload.Dragger>
          <Divider />
          <Button
            className="send-button"
            disabled={
              loading || fileList.length === 0 || !nombre || !lugar || !fecha
            }
            onClick={handleSubmit}
          >
            {loading ? (
              <SyncLoader color="#1c2c6d" className="loader" />
            ) : (
              "Enviar"
            )}
          </Button>
        </Form>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
      >
        <NavLink to="/">
          <ButtonLgg label="Volver al Menú" />
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default NewAudio;
