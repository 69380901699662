import React, { useEffect } from "react";
import { Button, Drawer, Input, Row, Space, Tooltip, Tree } from "antd";
import EditorActionPanel from "../EditorActionPanel";
import { DownOutlined } from "@ant-design/icons";
export interface nameInterface {
  name: string;
  times_mentioned: number;
  text_quotes: Array<string>;
  quotes: Array<{
    word_count: number;
    start: number;
    end: number;
    words: Array<{ word: string; start: number; end: number }>;
    edited?: boolean;
    deleted?: boolean;
  }>;
  newName?: string;
  edited?: boolean;
  deleted?: boolean;
}

const Names: React.FC<{
  names: nameInterface[];
  onChange: () => void;
  onSave: (names: nameInterface[]) => void;
  onUndo: () => void;
}> = ({ names, onChange, onSave, onUndo }) => {
  const [isEdited, setIsEdited] = React.useState(false);
  const [editedNames, setEditedNames] = React.useState<nameInterface[]>([]);
  const [isNameDrawerOpen, setIsNameDrawerOpen] = React.useState(false);
  const [editingName, setEditingName] = React.useState<{
    index: number;
    name: string;
    newName: string;
  }>();
  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);

  const handleNameEdit = (index: number, name: string) => {
    setEditingName({
      index,
      name,
      newName: name,
    });
    setIsNameDrawerOpen(true);
  };

  useEffect(() => {
    setEditedNames([...names]);
    setExpandedKeys(names.map((item, index) => `names-index-${index}`));
  }, [names]);

  const handleNameEditConfirm = () => {
    if (editingName == null) return;
    setEditedNames(
      editedNames.map((item, index) => {
        if (index === editingName.index) {
          return {
            ...item,
            newName: editingName.newName,
            edited: true,
          };
        }
        return item;
      })
    );
    onChange();
    setIsEdited(true);
    setIsNameDrawerOpen(false);
  };

  const handleNameDelete = (index: number) => {
    setEditedNames(
      editedNames.map((item, i) => ({
        ...item,
        quotes: item.quotes.map((quote) => ({
          ...quote,
          deleted: quote.deleted || i === index,
        })),
        deleted: item.deleted || i === index,
      }))
    );
    onChange();
    setIsEdited(true);
  };

  const handleNameUndo = (index: number) => {
    setEditedNames(
      editedNames.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            newName: item.name,
            edited: false,
          };
        }
        return item;
      })
    );
  };

  const handleQuoteDelete = (nameIndex: number, quoteIndex: number) => {
    setEditedNames(
      editedNames.map((item, index) => {
        if (index === nameIndex) {
          return {
            ...item,
            deleted:
              item.deleted ||
              item.quotes.every(
                (quote, qindex) => quote.deleted || qindex === quoteIndex
              ),
            quotes: item.quotes.map((quote, i) => ({
              ...quote,
              deleted: quote.deleted || i === quoteIndex,
            })),
          };
        }
        return item;
      })
    );
    onChange();
    setIsEdited(true);
  };

  const handleQuoteUndo = (nameIndex: number, quoteIndex: number) => {
    setEditedNames(
      editedNames.map((item, index) => {
        if (index === nameIndex) {
          return {
            ...item,
            deleted: false,
            quotes: item.quotes.map((quote, i) => ({
              ...quote,
              deleted: i === quoteIndex ? false : quote.deleted,
            })),
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      <Tree
        switcherIcon={<DownOutlined />}
        showLine
        expandedKeys={expandedKeys}
        defaultExpandParent={true}
        defaultExpandAll={true}
        onExpand={() => {
          setExpandedKeys([...expandedKeys]);
        }}
        treeData={editedNames.map((item, index) => ({
          key: `names-index-${index}`,
          title: (
            <div style={{ textAlign: "left", fontWeight: "bold" }}>
              <EditorActionPanel
                showUndo={item?.edited}
                showDelete={!item?.deleted}
                onEditAction={() => {
                  handleNameEdit(index, item.newName || item.name);
                }}
                onDeleteAction={() => {
                  handleNameDelete(index);
                }}
                onUndoAction={() => {
                  handleNameUndo(index);
                }}
              />
              <span
                style={
                  item?.deleted
                    ? {
                        textDecorationLine: "line-through",
                        textDecorationColor: "red",
                        textDecorationStyle: "solid",
                      }
                    : {}
                }
              >
                {item.newName || item.name}
              </span>
            </div>
          ),
          children: item.quotes.map((quote, quoteIndex) => ({
            key: `names-index-${index}-quote-${quoteIndex}`,
            title: (
              <div style={{ textAlign: "left" }}>
                <EditorActionPanel
                  showEdit={false}
                  showUndo={quote?.deleted}
                  showDelete={!quote?.deleted}
                  onEditAction={() => {}}
                  onDeleteAction={() => handleQuoteDelete(index, quoteIndex)}
                  onUndoAction={() => handleQuoteUndo(index, quoteIndex)}
                />
                <Tooltip
                  title={
                    "No es posible editar citas textuales, solo eliminarlas."
                  }
                >
                  <span
                    onClick={() => {}}
                    style={
                      quote?.deleted
                        ? {
                            textDecorationLine: "line-through",
                            textDecorationColor: "red",
                            textDecorationStyle: "solid",
                          }
                        : {}
                    }
                  >
                    {item.text_quotes[quoteIndex]}
                  </span>
                </Tooltip>
              </div>
            ),
          })),
        }))}
      />

      <Row justify={"end"}>
        <Button
          className="save-button"
          onClick={() => {
            onSave(editedNames);
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Guardar
        </Button>
        {/* <Button
          className="undo-button"
          onClick={() => {
            setEditedNames([...names]);
            onUndo();
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Deshacer
        </Button> */}
      </Row>
      <Drawer
        title={`Editar el nombre ${editingName?.name}`}
        placement={"bottom"}
        width={500}
        onClose={() => setIsNameDrawerOpen(false)}
        open={isNameDrawerOpen}
        extra={
          <Space>
            <Button onClick={() => setIsNameDrawerOpen(false)}>Cancelar</Button>
            <Button type="primary" onClick={handleNameEditConfirm}>
              Guardar
            </Button>
          </Space>
        }
      >
        <Input
          type="text"
          defaultValue={editingName?.newName || ""}
          value={editingName?.newName || ""}
          onChange={(e) => {
            if (editingName?.index != null && editingName?.name !== null)
              setEditingName({
                index: editingName?.index || 0,
                name: editingName?.name || "",
                newName: e.target.value,
              });
          }}
        />
      </Drawer>
    </>
  );
};

export default Names;
