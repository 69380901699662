import { Col, Row, Button } from "antd";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "react-spinners/PulseLoader";
import dayjs from "dayjs";
import "./Styles/ListAudios.css";
import CustomButton from "../Components/Button";
import { ClipLoader } from "react-spinners";

const ListAudios: React.FC = () => {
  const [audios, setAudios] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const fetchAudios = async () => {
    setLoading(true);
    const accessToken = await fetchAuthSession();
    axios
      .get(process.env.REACT_APP_API_URL + "/items", {
        headers: {
          Authorization: `Bearer ${accessToken.tokens?.accessToken.toString()}`,
        },
      })
      .then((response) => {
        setAudios(response.data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAudios();
  }, []);

  const handlePublishArticle = async (articleId: string) => {
    await fetch(`${process.env.REACT_APP_API_URL}/items/${articleId}/resume`, {
      method: "POST",
    });
    setAudios(
      audios.map((audio) =>
        audio.id === articleId ? { ...audio, unpublished: undefined } : audio
      )
    );
  };

  const handlePauseArticle = async (articleId: string) => {
    await fetch(`${process.env.REACT_APP_API_URL}/items/${articleId}/pause`, {
      method: "POST",
    });
    setAudios(
      audios.map((audio) =>
        audio.id === articleId ? { ...audio, unpublished: dayjs() } : audio
      )
    );
  };

  return (
    <div>
      {loading && <Loader color="#1890ff" style={{ alignSelf: "center" }} />}
      {!loading && (
        <div className="listAudiosContainer">
          <h2>Listado de Transcripciones Generadas</h2>
          <div className="listAudiosLinesHeader">
            <div>Nombre</div>
            <div>Lugar</div>
            {/*<div>Contexto</div>*/}
            <div>Fecha</div>
            <div>Ver</div>
            <div>Publicado</div>
          </div>
          <div
            style={{ textAlign: "left" }}
            children={audios.map(
              (audio: {
                id: string;
                nombre: string;
                lugar: string;
                fecha: string;
                estado: string;
                porcentaje: number;
                unpublished?: string;
              }) => {
                return (
                  <div key={audio.id} className="listAudiosLines">
                    <span>{audio.nombre}</span> <span>{audio.lugar}</span>{" "}
                    {/*<span>
                      Lorem ipsum dolor
                      <span style={{ color: "#333fc3" }}>...</span>
                    </span>*/}
                    <span>{dayjs(audio.fecha).format("DD/MM/YYYY")}</span>
                    <Row>
                      <Col span={12}>
                        {
                          <>
                            {/* <a
                              style={{ marginRight: 20 }}
                              href={`${process.env.PUBLIC_URL}/widget.html?articleId=${audio.id}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Vista previa
                            </a>{" "} */}
                            <NavLink
                              style={{ marginRight: 20 }}
                              to={`/preview/${audio.id}`}
                            >
                              <Button type="primary">Ver</Button>
                            </NavLink>
                          </>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        {
                          <>
                            {audio.porcentaje < 100 ? (
                              <div style={{display:"flex", alignItems:"center"}}>
                                <div style={{paddingRight:"5px"}}>Procesando {audio.porcentaje}%</div>
                                <ClipLoader size="20px" color="#1890ff" />
                              </div>
                            ) : audio.unpublished ? (
                              <>
                              <div style={{display:"flex", alignItems:"center"}}>
                                <div style={{textDecoration:"underline"}} className="lggHover" onClick={() => handlePublishArticle(audio.id)}>Publicar</div>
                                <div style={{paddingRight:"3px",paddingLeft:"3px"}}>/</div>
                                <div style={{textDecoration:"underline", color:"green"}}>Pausado</div>
                              </div>
                              </>
                            ) : (
                              <>                              
                              <div style={{display:"flex", alignItems:"center"}}>
                                <div style={{textDecoration:"underline", color:"green"}}>Publicado</div>
                                <div style={{paddingRight:"3px",paddingLeft:"3px"}}>/</div>
                                <div style={{textDecoration:"underline"}} className="lggHover" onClick={() => handlePauseArticle(audio.id)}>Pausar</div>
                              </div>
                              </>
                            )}
                          </>
                        }
                      </Col>
                    </Row>
                  </div>
                );
              }
            )}
          />
        </div>
      )}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
      >
        <NavLink to="/">
          <CustomButton label="Volver al Menú" />
        </NavLink>
      </div>
    </div>
  );
};

export default ListAudios;
