import { withAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home";
import { EditModules } from "./Screens/EditModules";
import EditTranscript from "./Screens/EditTranscript";
import Upload from "./Screens/NewAudio";
import "./App.css";
import ListAudios from "./Screens/ListAudios";
import { WidgetPreview } from "./Screens/WidgetPreview";
import Header from "./Components/Header";
import ScrollToTop from "./Components/ScrollTop";

const App = () => {
  return (
    <BrowserRouter basename={`/${process.env.REACT_APP_BASE_URL}`}>
      <ScrollToTop />
      <Header />
      <div className="App">
        <div className="main-content">
          <Routes>
            <Route path="list-audios" element={<ListAudios />} />
            <Route path="/" element={<Home />} />
            <Route
              path="/edit-transcript/:articleId"
              element={<EditTranscript />}
            />
            <Route path="/edit-modules/:articleId" element={<EditModules />} />
            <Route path="/preview/:articleId" element={<WidgetPreview />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/list" element={<ListAudios />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default withAuthenticator(App);
