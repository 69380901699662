const awsExports = {
  aws_project_region: "sa-east-1",
  aws_cognito_identity_pool_id:
    "sa-east-1:8f27842e-d671-4438-8077-3b07faa8545f",
  aws_cognito_region: "sa-east-1",
  aws_user_pools_id: "sa-east-1_ucEPxbsZm",
  aws_user_pools_client_id: "2vvdinlketpc9v5d2668v8ie7i",
  aws_user_pools_web_client_id: "2vvdinlketpc9v5d2668v8ie7i",
  oauth: {
    domain: "https://ualter-sound.auth.sa-east-1.amazoncognito.com",
    scope: ["email"],
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsExports;
