import React, { useEffect } from "react";
import { Button, Drawer, Input, Row, Space, Tooltip, Tree } from "antd";
import EditorActionPanel from "../EditorActionPanel";
import { DownOutlined } from "@ant-design/icons";

export interface thematicIndexInterface {
  titulo: string;
  edited?: boolean;
  deleted?: boolean;
  textuales: Array<{
    end: number;
    start: number;
    text: string;
    words: {
      word: string;
      start: number;
      end: number;
    };
    edited?: boolean;
    deleted?: boolean;
  }>;
}

const ThematicIndex: React.FC<{
  thIndex: Array<thematicIndexInterface>;
  onChange: () => void;
  onSave: (index: Array<thematicIndexInterface>) => void;
  onUndo: () => void;
}> = ({ thIndex, onChange, onSave, onUndo }) => {
  const [isEdited, setIsEdited] = React.useState(false);
  const [editedIndex, setEditedIndex] = React.useState<
    thematicIndexInterface[]
  >([]);
  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = React.useState(false);
  const [editingCategory, setEditingCategory] = React.useState<{
    index: number;
    titulo: string;
    newValue: string;
  }>();
  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);

  useEffect(() => {
    setEditedIndex(thIndex);
    setExpandedKeys(thIndex.map((item, index) => `thematic-index-${index}`));
  }, [thIndex]);

  const handleCategoryEdit = (index: number, titulo: string) => {
    setEditingCategory({ index, titulo, newValue: titulo });
    setIsCategoryDrawerOpen(true);
  };

  const handleCategoryEditConfirm = () => {
    if (editingCategory?.index == null) return;
    setEditedIndex(
      editedIndex.map((item, index) => {
        if (index === editingCategory?.index) {
          return {
            ...item,
            titulo: editingCategory?.newValue || item.titulo,
            edited: true,
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
    setIsCategoryDrawerOpen(false);
  };

  const handleCategoryDelete = (index: number) => {
    setEditedIndex(
      editedIndex.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            deleted: true,
            textuales: item.textuales.map((quote) => ({
              ...quote,
              deleted: true,
            })),
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
  };

  const handleSubtitleDelete = (
    categoryIndex: number,
    subtitleIndex: number
  ) => {
    setEditedIndex(
      editedIndex.map((item, index) => {
        if (index === categoryIndex) {
          return {
            ...item,
            deleted:
              item.deleted ||
              item.textuales.every(
                (quote, qindex) => quote.deleted || qindex === subtitleIndex
              ),
            textuales: item.textuales.map((subsubitem, subindex) => ({
              ...subsubitem,
              deleted: subindex === subtitleIndex ? true : subsubitem.deleted,
            })),
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
  };

  const handleCategoryUndo = (categoryIndex: number) => {
    setEditedIndex(
      editedIndex.map((item, i) => {
        if (i === categoryIndex) {
          return {
            ...item,
            titulo: thIndex[categoryIndex].titulo,
            deleted: false,
            edited: false,
          };
        }
        return item;
      })
    );
  };

  const handleSubtitleUndo = (categoryIndex: number, subindex: number) => {
    setEditedIndex(
      editedIndex.map((item, i) => {
        if (i === categoryIndex) {
          return {
            ...item,
            deleted: false,
            textuales: item.textuales.map((subitem, j) => {
              if (j === subindex) {
                return {
                  ...thIndex[categoryIndex].textuales[subindex],
                };
              }
              return subitem;
            }),
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      <Tree
        switcherIcon={<DownOutlined />}
        showLine
        expandedKeys={expandedKeys}
        defaultExpandParent={true}
        defaultExpandAll={true}
        onExpand={() => {
          setExpandedKeys([...expandedKeys]);
        }}
        treeData={editedIndex.map((item, index) => ({
          key: `thematic-index-${index}`,
          title: (
            <div style={{ textAlign: "left", fontWeight: "bold" }}>
              <EditorActionPanel
                showUndo={item?.edited}
                showDelete={!item?.deleted}
                onEditAction={() => {
                  handleCategoryEdit(index, item.titulo);
                }}
                onDeleteAction={() => {
                  handleCategoryDelete(index);
                }}
                onUndoAction={() => {
                  handleCategoryUndo(index);
                }}
              />
              <span
                style={
                  item?.deleted
                    ? {
                        textDecorationLine: "line-through",
                        textDecorationColor: "red",
                        textDecorationStyle: "solid",
                      }
                    : {}
                }
              >
                {item.titulo}
              </span>
            </div>
          ),
          children: item.textuales.map((quote, quoteIndex) => ({
            key: `thematic-index-${index}-quote-${quoteIndex}`,
            title: (
              <div style={{ textAlign: "left" }}>
                <EditorActionPanel
                  showEdit={false}
                  showUndo={quote?.deleted}
                  showDelete={!quote?.deleted}
                  onEditAction={() => {}}
                  onDeleteAction={() => handleSubtitleDelete(index, quoteIndex)}
                  onUndoAction={() => handleSubtitleUndo(index, quoteIndex)}
                />
                <Tooltip
                  title={
                    "No es posible editar citas textuales, solo eliminarlas."
                  }
                >
                  <span
                    onClick={() => {}}
                    style={
                      quote?.deleted
                        ? {
                            textDecorationLine: "line-through",
                            textDecorationColor: "red",
                            textDecorationStyle: "solid",
                          }
                        : {}
                    }
                  >
                    {quote.text}
                  </span>
                </Tooltip>
              </div>
            ),
          })),
        }))}
      />
      <Row justify={"end"}>
        <Button
          className="save-button"
          onClick={() => {
            onSave(editedIndex);
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Guardar
        </Button>
        {/* <Button
          className="undo-button"
          onClick={() => {
            setEditedIndex([...thIndex]);
            onUndo();
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Deshacer
        </Button> */}
      </Row>
      <Drawer
        title={`Editar categoría ${editingCategory?.titulo}`}
        placement={"bottom"}
        width={500}
        onClose={() => setIsCategoryDrawerOpen(false)}
        open={isCategoryDrawerOpen}
        extra={
          <Space>
            <Button onClick={() => setIsCategoryDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button type="primary" onClick={handleCategoryEditConfirm}>
              Guardar
            </Button>
          </Space>
        }
      >
        <Input
          type="text"
          defaultValue={editingCategory?.titulo || ""}
          value={editingCategory?.newValue || ""}
          onChange={(e) => {
            if (
              editingCategory?.index != null &&
              editingCategory?.titulo !== null
            )
              setEditingCategory({
                index: editingCategory?.index || 0,
                titulo: editingCategory?.titulo || "",
                newValue: e.target.value,
              });
          }}
        />
      </Drawer>
    </>
  );
};

export default ThematicIndex;
