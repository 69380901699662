import React from "react";
import "./Styles/Button.css";

type ButtonProps = {
  label: string;
  onClick?: () => void;
};

const CustomButton: React.FC<ButtonProps> = ({ label, onClick }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div className="button" onClick={handleClick}>
      {label}
    </div>
  );
};

export default CustomButton;
