import React from 'react';
import {
	View,
	Image,
	useTheme,
	Text,
	useAuthenticator,
	Button,
} from '@aws-amplify/ui-react';
import { Typography } from 'antd';
import '@aws-amplify/ui-react/styles.css';
import './Styles/CustomAuthenticator.css';

const components = {
	Header() {
		return (
			<View
				className="auth-header"
				style={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '12px 0',
					gap: '6px',
				}}
			>
				<Image
					alt="Ualter logo"
					src={`${process.env.PUBLIC_URL}/ualterlogo.svg`}
					className="mx-auto"
					style={{ width: '170px', height: '36px' }}
				/>
				<h3
					className="mt-4 text-lg font-semibold text-white soundtxt"
					style={{ margin: 0, color: 'white' }}
				>
					SOUND
				</h3>
			</View>
		);
	},
	Footer() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Text color={tokens.colors.neutral[80]}>&copy; Ualter 2024</Text>
			</View>
		);
	},

	SignIn: {
		Header() {
			return (
				<Typography.Title level={3} style={{ textAlign: 'center' }}>
					Iniciar Sesión
				</Typography.Title>
			);
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();

			return (
				<View textAlign="center">
					<Button
						fontWeight="normal"
						onClick={toForgotPassword}
						size="small"
						variation="link"
					>
						Olvidé mi constraseña
					</Button>
				</View>
			);
		},
	},

	ConfirmSignIn: {
		Header() {
			return (
				<Typography.Title level={3} style={{ textAlign: 'center' }}>
					Complete el registro
				</Typography.Title>
			);
		},
		Footer() {
			return <></>;
		},
	},
	ForgotPassword: {
		Header() {
			return (
				<Typography.Title level={3} style={{ textAlign: 'center' }}>
					Ingrese su e-mail
				</Typography.Title>
			);
		},
		Footer() {
			return <></>;
		},
	},
	ConfirmResetPassword: {
		Header() {
			return (
				<Typography.Title level={3} style={{ textAlign: 'center' }}>
					Elija una nueva contraseña
				</Typography.Title>
			);
		},
		Footer() {
			return <></>;
		},
	},
};

const formFields = {
	signIn: {
		username: {
			placeholder: 'Ingrese su email',
		},
	},
	signUp: {
		password: {
			label: 'Contraseña:',
			placeholder: 'Ingrese su contraseña',
			isRequired: false,
			order: 2,
		},
		confirm_password: {
			label: 'Confirme su contraseña',
			order: 1,
		},
	},
	forceNewPassword: {
		password: {
			placeholder: 'Ingrese su nueva contraseña',
		},
	},
	forgotPassword: {
		username: {
			placeholder: 'Ingrese su email',
		},
	},
	confirmResetPassword: {
		confirmation_code: {
			placeholder: 'Ingrese su código de confirmación',
			label: 'Código de confirmación',
			isRequired: false,
		},
		confirm_password: {
			placeholder: 'Confirme su contraseña',
		},
	},
	setupTotp: {
		QR: {
			totpIssuer: 'test issuer',
			totpUsername: 'amplify_qr_test_user',
		},
		confirmation_code: {
			label: 'Código de confirmación',
			placeholder: 'Ingrese su código de confirmación:',
			isRequired: false,
		},
	},
	confirmSignIn: {
		confirmation_code: {
			label: 'Código de confirmación',
			placeholder: 'Ingrese su código de confirmación:',
			isRequired: false,
		},
	},
};

export { components, formFields };
