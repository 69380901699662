import { Button, Modal, Row } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import "./Styles/EditModules.css";
import TranscriptSegment from "../Components/Editor/TranscriptSegment";
import { forTranscript as handleGetSignedUrl } from "../util/getSignedUrl";
import ReactPlayer from "react-player";

export interface whisperWordInterface {
  word: string;
  start: number;
  end: number;
  style?: string;
}
export interface transcriptInterface {
  start: number;
  end: number;
  words: Array<whisperWordInterface>;
  text_content: string;
  edited: boolean;
  deleted: boolean;
  editing: boolean;
}

const EditTranscript: React.FC = () => {
  const { articleId } = useParams();
  const [transcript, setTranscript] = useState<Array<transcriptInterface>>([]);
  const [editedTranscript, setEditedTranscript] = useState<
    Array<transcriptInterface>
  >([...transcript]);
  // const [edited, setEdited] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSegmentPlaying, setCurrentSegmentPlaying] = useState(-1);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const audioRef = useRef<any>(null);

  const handleSave = () => {
    setModalOpen(true);
  };

  const handleModalCancel = (e: React.MouseEvent<HTMLElement>) => {
    setModalOpen(false);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/fetch/" + articleId)
      .then((response) => {
        const segments = response.data.timestamps
          .reduce(
            (acc: any, curr: any) => {
              if (curr.style === "linebreak") {
                acc[acc.length - 1].push(curr);
                acc.push([]);
              } else {
                acc[acc.length - 1].push(curr);
              }
              return acc;
            },
            [[]]
          )
          .filter((segment: any) => segment.length > 0)
          .map((segment: any) => {
            return {
              start: segment[0].start,
              end: segment[segment.length - 1].end,
              words: segment,
              text_content: segment
                .map((word: any) => word.word)
                .join(" ")
                .replace(/\s+/g, " "),
              edited: false,
              deleted: false,
            };
          });
        setTranscript(segments);
        setEditedTranscript([...segments]);
      });
  }, [articleId]);

  const handleModalOk = async () => {
    // setEdited(false);
    setEditedTranscript([
      ...editedTranscript.map((segment) => ({ ...segment, edited: false })),
    ]);
    setTranscript([...editedTranscript]);
    const text = editedTranscript
      .map((segment) => segment.text_content)
      .join(" ");
    const url = await handleGetSignedUrl(articleId);
    axios
      .put(url, JSON.stringify({ text }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
    setModalOpen(false);
  };

  const handleSegmentEdit = (segment: transcriptInterface) => {
    setEditedTranscript([
      ...editedTranscript.map((item) => {
        if (item.start === segment.start) {
          return segment;
        }
        return item;
      }),
    ]);
    // setEdited(true);
  };

  const handleSegmentUndo = (segment: transcriptInterface) => {
    setEditedTranscript([
      ...editedTranscript.map((item) => {
        if (item.start === segment.start) {
          return segment;
        }
        return item;
      }),
    ]);
  };

  // const handleUndo = () => {
  //   setEditedTranscript([
  //     ...transcript.map((segment) => ({
  //       ...segment,
  //       edited: false,
  //       editing: false,
  //     })),
  //   ]);
  //   setEdited(false);
  // };

  const findCurrentSegment = (timestamp: number) => {
    if (timestamp <= transcript[0].end) {
      setCurrentSegmentPlaying(0);
    } else if (timestamp >= transcript[transcript.length - 1].end) {
      setCurrentSegmentPlaying(transcript.length - 1);
    } else {
      for (let j = transcript.length - 1; j >= 0; j--) {
        if (transcript[j].end <= timestamp) {
          setCurrentSegmentPlaying(j + 1);
          break;
        }
      }
    }
  };

  const handleProgress = (progress: any) => {
    setCurrentTimestamp(progress.playedSeconds);
    if (
      currentSegmentPlaying !== -1 &&
      progress.playedSeconds > transcript[currentSegmentPlaying].end
    ) {
      setCurrentSegmentPlaying(
        currentSegmentPlaying + 1 === transcript.length
          ? -1
          : currentSegmentPlaying + 1
      );
    }
  };

  const handlePlay = () => {
    if (currentSegmentPlaying === -1) {
      findCurrentSegment(audioRef.current.getCurrentTime());
    }
  };

  const handlePause = () => {
    setCurrentSegmentPlaying(-1);
  };

  return (
    <div className="edit-modules">
      <div className="transcriptHeader">
        <div className="transcriptHeaderTitle">Corregir Transcripción</div>
        <Row style={{ justifyContent: "right" }}>
          <ReactPlayer
            ref={audioRef}
            url={`${process.env.REACT_APP_AUDIO_URL}/${articleId}/browser.mp3`}
            onProgress={handleProgress}
            onPlay={handlePlay}
            onPause={handlePause}
            width="10px"
            height="50px"
            progressInterval={250}
            controls={false}
          />
        </Row>
      </div>
      {transcript.map((segment, index) => {
        return (
          <TranscriptSegment
            key={index}
            isPlaying={currentSegmentPlaying === index ? currentTimestamp : -1}
            originalSegment={segment}
            editedSegment={editedTranscript[index]}
            onPlay={(start: number, end: number) => {
              if (
                !audioRef.current.getInternalPlayer().paused &&
                index === currentSegmentPlaying
              ) {
                audioRef.current.getInternalPlayer().pause();
              } else {
                setCurrentTimestamp(start);
                audioRef.current.seekTo(start, "seconds");
                audioRef.current.getInternalPlayer().play();
                setCurrentSegmentPlaying(index);
              }
            }}
            canEdit={!editedTranscript.some((e) => e.editing)}
            onEdit={(segment: transcriptInterface) => {
              audioRef.current.getInternalPlayer().pause();
              handleSegmentEdit(segment);
              // setEdited(true);
            }}
            onDelete={(segment: transcriptInterface) => {}}
            onUndo={(segment: transcriptInterface) => {
              handleSegmentUndo(segment);
            }}
          />
        );
      })}
      <Modal
        title="¿Guardar cambios?"
        open={modalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okButtonProps={{ type: "primary", name: "Confirmar" }}
        cancelButtonProps={{ name: "Cancelar", type: "default" }}
      >
        <p style={{ fontSize: "large", fontWeight: "bold" }}>
          Al guardar la transcripción se debe volver a generar cada uno de los
          módulos y los videos con el nuevo contexto.
        </p>
      </Modal>
      <div className="transcriptFooter">
        <Button
          disabled={
            editedTranscript.some((e) => e.editing) ||
            editedTranscript.findIndex((e) => e.edited) === -1
          }
          className="transcriptFooterBtn"
          onClick={() => handleSave()}
          style={{ marginRight: "15px" }}
        >
          Guardar Correcciones
        </Button>
      </div>
      <NavLink to={`/preview/${articleId}`}>
        <Button className="goback-button">Menú Widget</Button>
      </NavLink>
    </div>
  );
};

export default EditTranscript;
