import React, { useRef, useEffect } from "react";
import EditorActionPanel from "../EditorActionPanel";
import { transcriptInterface } from "../../Screens/EditTranscript";
import { Divider, Input } from "antd";
import {
  PlayCircleFilled,
  PauseCircleFilled,
  EditOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "./TranscriptSegment.css";

const TranscriptSegment: React.FC<{
  isPlaying: number;
  originalSegment: transcriptInterface;
  editedSegment: transcriptInterface;
  canEdit: boolean;
  onEdit: (segment: transcriptInterface) => void;
  onUndo: (segment: transcriptInterface) => void;
  onDelete: (segment: transcriptInterface) => void;
  onPlay: (start: number, end: number) => void;
}> = ({
  isPlaying,
  originalSegment,
  editedSegment,
  canEdit,
  onPlay,
  onEdit,
  onUndo,
  onDelete,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [newTextContent, setNewTextContent] = React.useState(
    editedSegment.text_content
  );

  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    setNewTextContent(editedSegment.text_content);
  }, [editedSegment]);

  useEffect(() => {
    fixTextarea();
    if (wrapperRef.current) {
      wrapperRef.current.querySelector("textarea").focus();
    }
  }, [newTextContent, isEditing]);

  const fixTextarea = () => {
    if (wrapperRef.current) {
      const textArea = wrapperRef.current.querySelector("textarea");
      if (textArea) {
        textArea.style.height = "auto";
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    }
  };

  const handleStartEdit = () => {
    if (!canEdit) {
      alert(
        "Hay un párrafo actualmente en edición. Por favor, finalice la corrección de otros párrafos antes de continuar"
      );
      return;
    }
    setNewTextContent(
      editedSegment.edited ? newTextContent : editedSegment.text_content
    );
    onEdit({ ...editedSegment, editing: true });
    setIsEditing(true);
  };

  const handleEditSegmentConfirmation = () => {
    onEdit({
      ...editedSegment,
      text_content: newTextContent,
      edited: true,
      editing: false,
    });
    setIsEditing(false);
  };

  const handleEditSegmentCancel = () => {
    setNewTextContent(editedSegment.text_content);
    setIsEditing(false);
    onEdit({ ...editedSegment, editing: false });
  };

  const handleUndoAction = () => {
    setNewTextContent(originalSegment.text_content);
    onUndo({ ...originalSegment, edited: false });
  };
  return (
    <div className="transcriptSegmentContainer">
      <div className="transcriptPlayContainer">
        {isPlaying !== -1 ? (
          <PauseCircleFilled
            style={{ fontSize: "36px", color: "#1c2c6d" }}
            onClick={() => onPlay(originalSegment.start, originalSegment.end)}
          />
        ) : (
          <PlayCircleFilled
            style={{ fontSize: "36px", color: "#1c2c6d" }}
            onClick={() => {
              onPlay(originalSegment.start, originalSegment.end);
            }}
          />
        )}
        <p
          style={{ fontSize: "Large", fontWeight: "300", padding: "5px" }}
        >{`${Math.floor(originalSegment.start / 60)}m${Math.floor(
          originalSegment.start % 60
        )}s`}</p>
      </div>
      <div className="transcriptTextContainer">
        <div
          className={`segment_text${editedSegment.edited ? "_edited" : ""}`}
          onClick={handleStartEdit}
        >
          {originalSegment.words.map((word, index) => {
            if (
              isPlaying >= word.start &&
              isPlaying < originalSegment.words[index + 1]?.start
            ) {
              return (
                <span key={index} className="is_playing">
                  {word.word}{" "}
                </span>
              );
            } else {
              return <span key={index}>{word.word} </span>;
            }
          })}

          {!isEditing && !editedSegment.edited && (
            <div className="btnLgg">
              <span style={{ color: canEdit ? "#333FC3" : "#CCC" }}>
                Corregir transcripción
              </span>
              <EditOutlined
                style={{
                  fontSize: "30px",
                  color: canEdit ? "#333FC3" : "#CCC",
                  marginLeft: "10px",
                }}
              />
            </div>
          )}
        </div>
        {(isEditing || editedSegment.deleted || editedSegment.edited) && (
          <>
            {isEditing && (
              <div className="transcriptEditContainer">
                <div ref={wrapperRef}>
                  <Input.TextArea
                    value={newTextContent}
                    style={{ outline: "none", border: "0" }}
                    onChange={(e) => {
                      setNewTextContent(e.target.value);
                    }}
                    disabled={!isEditing}
                  />
                </div>
                <div className="transcriptEditBtns">
                  {isEditing && (
                    <>
                      <div
                        className="btnTranscriptControl changes-confirm"
                        onClick={handleEditSegmentConfirmation}
                      >
                        Confirmar cambios
                      </div>
                      <div
                        className="btnTranscriptControl noChanges"
                        onClick={handleEditSegmentCancel}
                      >
                        No realizar cambios
                      </div>
                    </>
                  )}
                  {!isEditing && editedSegment.edited && (
                    <EditorActionPanel
                      showDelete={false}
                      showEdit={false}
                      showUndo={editedSegment.edited || editedSegment.deleted}
                      onDeleteAction={() => {}}
                      onEditAction={() => {}}
                      onUndoAction={handleUndoAction}
                    />
                  )}
                </div>
              </div>
            )}
            {!isEditing && editedSegment.edited && (
              <div className="transcriptTextEdited">
                <p>{editedSegment.text_content}</p>

                <div className="CorregirTranscript">
                  {/*<div className="btnLgg" 
                onClick={() => {
                  if (!isEditing) handleStartEdit();
                }}>
                  <span style={{color:"#1c2c6d"}}>Corregir transcripción</span>
                  <EditOutlined style={{fontSize: "30px", color:"#1c2c6d", marginLeft:"10px"}}/>
                </div>*/}
                  <div
                    className="btnLgg btnSegmentoCancelar"
                    onClick={handleUndoAction}
                  >
                    <span>Cancelar correccion</span>
                  </div>
                </div>

                <div
                  className="btnSegmentoCorregido"
                  style={{ textAlign: "right" }}
                >
                  <span>Corregido</span>
                  <CheckCircleFilled
                    style={{
                      fontSize: "26px",
                      color: "#2c6d1c",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default TranscriptSegment;
