import "./Styles/Header.css";
import { signOut, getCurrentUser } from "aws-amplify/auth";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

const handleLogoutAction = async () => {
  await signOut();
};

const Header = () => {
  const [username, setUsername] = useState<string | undefined>(undefined);
  useEffect(() => {
    getCurrentUser().then((user) => {
      setUsername(user.signInDetails?.loginId);
    });
  }, []);

  return (
    <div className="header">
      {/*<div className="headerArrowContainer" onClick={() => {window.location.href='/'}}>
            <ArrowLeftOutlined className="headerArrow"/>
            <div>Volver al menu</div>
            </div>*/}
      {username && (
        <div className="ualterUser">
          Usuario: {username} |{" "}
          <NavLink to="#" onClick={handleLogoutAction}>
            Cerrar sesión
          </NavLink>
        </div>
      )}
      <div>
        <Logo />
        <div className="headerSubtitle">SOUND</div>
      </div>
      <div className="ualterVersion">
        UalterSound Versión Beta v0.9 - Fecha: 03/07/24
      </div>
    </div>
  );
};

export default Header;
