import { fetchAuthSession } from "aws-amplify/auth";
import { moduleType } from "../Screens/EditModules";
import axios from "axios";

const forModule = async (articleId: string | undefined, module: moduleType) => {
  if (articleId === undefined) {
    throw new Error("Article ID is undefined");
  }
  const accessToken = await fetchAuthSession();
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/edit/${articleId}/modules/${module}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken.tokens?.accessToken.toString()}`,
      },
    }
  );
  return response.data.url;
};

const forTranscript = async (articleId: string | undefined) => {
  if (articleId === undefined) {
    throw new Error("Article ID is undefined");
  }
  const accessToken = await fetchAuthSession();
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/edit/${articleId}/transcript`,
    {
      headers: {
        Authorization: `Bearer ${accessToken.tokens?.accessToken.toString()}`,
      },
    }
  );
  return response.data.url;
};

export { forModule, forTranscript };
