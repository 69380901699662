import { Button, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Resume, { ResumeProps } from "../Components/Editor/Resume";
import ThematicIndex, {
  thematicIndexInterface,
} from "../Components/Editor/ThematicIndex";
import Names, { nameInterface } from "../Components/Editor/Names";
import Numbers, { numbersInterface } from "../Components/Editor/Numbers";
// import { glossaryInterface } from "../Components/Editor/Glossary";
import { WarningFilled } from "@ant-design/icons";
import { forModule as handleGetSignedUrl } from "../util/getSignedUrl";
import { PulseLoader } from "react-spinners";

export type moduleType =
  | "resume"
  | "thematicIndex"
  | "names"
  | "numbers"
  | "glossary";

export const EditModules: React.FC = () => {
  const { articleId } = useParams();
  const [resume, setResume] = useState<ResumeProps>({
    resumen: "",
    titulos: [],
    titulo: "",
  });
  const [resumeIsEdited, setResumeIsEdited] = useState<boolean>(false);
  const [index, setIndex] = useState<Array<thematicIndexInterface>>([]);
  const [indexIsEdited, setIndexIsEdited] = useState<boolean>(false);
  const [names, setNames] = useState<nameInterface[]>([]);
  const [namesIsEdited, setNamesIsEdited] = useState<boolean>(false);
  const [numbers, setNumbers] = useState<Array<numbersInterface>>([]);
  const [numbersIsEdited, setNumbersIsEdited] = useState<boolean>(false);
  // const [glossary, setGlossary] = useState<Array<glossaryInterface>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/fetch/" + articleId)
      .then((response) => {
        setResume({ ...response.data.resumen });
        setIndex(response.data.indice_tematico);
        setNames(response.data.nombres);
        setNumbers(
          response.data.numeros.map((item: numbersInterface) => ({
            ...item,
            datos: item.datos.filter((subitem) => subitem),
          }))
        );
        // setGlossary(response.data.glosario.glrosario);
      });
  }, [articleId]);

  const handleSaveResume = async (resume: ResumeProps) => {
    setLoading(true);
    setResume(resume);
    const url = await handleGetSignedUrl(articleId, "resume");
    axios
      .put(url, JSON.stringify(resume), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setResumeIsEdited(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveNumbers = async (numbers: Array<numbersInterface>) => {
    setLoading(true);
    numbers = numbers
      .filter((item) => !item.deleted)
      .filter((item) => !item.datos.every((subitem) => subitem.deleted))
      .map((item) => {
        return {
          categoria: item.categoria,
          datos: item.datos.filter((subitem) => !subitem.deleted),
        };
      });
    setNumbers(numbers);
    const url = await handleGetSignedUrl(articleId, "numbers");
    axios
      .put(url, JSON.stringify(numbers), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setNumbersIsEdited(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveNames = async (names: nameInterface[]) => {
    setLoading(true);
    names = names
      .filter((item) => !item?.deleted)
      .map((item) => {
        if (item.edited) item.name = item.newName || item.name;
        delete item.deleted;
        delete item.edited;
        return {
          ...item,
          text_quotes: item.text_quotes.filter(
            (quote, index) => !item.quotes[index].deleted
          ),
          quotes: item.quotes
            .filter((quote) => !quote.deleted)
            .map((quote) => {
              delete quote.deleted;
              delete quote.edited;
              return quote;
            }),
        };
      });

    console.log(
      names.filter((item) => item.text_quotes.length !== item.quotes.length)
    );
    setNames(names);
    const url = await handleGetSignedUrl(articleId, "names");
    axios
      .put(url, JSON.stringify(names), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setNamesIsEdited(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveIndex = async (thIndex: Array<thematicIndexInterface>) => {
    setLoading(true);
    thIndex = thIndex
      .filter(
        (item) =>
          !item.deleted && !item.textuales.every((quote) => quote.deleted)
      )
      .map((item) => {
        return {
          titulo: item.titulo,
          textuales: item.textuales.filter((quote) => !quote.deleted),
        };
      });
    setIndex(thIndex);
    const url = await handleGetSignedUrl(articleId, "thematicIndex");
    axios
      .put(url, JSON.stringify(thIndex), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setIndexIsEdited(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="edit-modules">
      <h2>Edición de Módulos</h2>
      {loading && <PulseLoader color="#1890ff" />}
      {!loading && (
        <Tabs
          style={{ width: "80vw" }}
          items={[
            {
              label: "Resumen",
              icon: resumeIsEdited && (
                <WarningFilled style={{ color: "darkred" }} />
              ),
              key: "1",
              children: (
                <Resume
                  resume={resume}
                  onChange={() => setResumeIsEdited(true)}
                  onSave={handleSaveResume}
                  onUndo={() => setResumeIsEdited(false)}
                />
              ),
            },
            {
              label: "Índice temático",
              icon: indexIsEdited && (
                <WarningFilled style={{ color: "darkred" }} />
              ),
              key: "2",
              children: (
                <ThematicIndex
                  thIndex={index}
                  onSave={handleSaveIndex}
                  onChange={() => setIndexIsEdited(true)}
                  onUndo={() => setIndexIsEdited(false)}
                />
              ),
            },
            {
              label: "Nombres",
              key: "3",
              icon: namesIsEdited && (
                <WarningFilled style={{ color: "darkred" }} />
              ),
              children: (
                <Names
                  names={names}
                  onChange={() => setNamesIsEdited(true)}
                  onSave={handleSaveNames}
                  onUndo={() => setNamesIsEdited(false)}
                />
              ),
            },
            {
              label: "Datos",
              key: "4",
              icon: numbersIsEdited && (
                <WarningFilled style={{ color: "darkred" }} />
              ),
              children: (
                <Numbers
                  numbers={numbers}
                  onSave={handleSaveNumbers}
                  onChange={() => setNumbersIsEdited(true)}
                  onUndo={() => setNumbersIsEdited(false)}
                />
              ),
            },
          ]}
        />
      )}
      <NavLink to={`/preview/${articleId}`}>
        <Button className="goback-button">Menú Widget</Button>
      </NavLink>
    </div>
  );
};
