import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
import { components, formFields } from './Components/CustomAuthenticator';

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolEndpoint:
				'https://cognito-idp.sa-east-1.amazonaws.com/sa-east-1_ucEPxbsZm',
			userPoolId: awsExports.aws_user_pools_id,
			userPoolClientId: awsExports.aws_user_pools_web_client_id,
		},
	},
});
I18n.setLanguage('es');
const dict = {
	es: {
		'Sign in': 'Iniciar sesión',
		'Sign In': 'Iniciar sesión',
		'Sign Up': 'Regístrate',
		'Sign Out': 'Cerrar sesión',
		Username: 'Usuario',
		Password: 'Contraseña',
		'New Password': 'Nueva contraseña',
		'Forgot your password?': '¿Olvidaste tu contraseña?',
		'No account?': '¿No tienes una cuenta?',
		'Create account': 'Crea una cuenta',
		'Reset password': 'Restablecer la contraseña',
		'Password is hidden': 'La contraseña está oculta',
		'Password is shown': 'La contraseña se muestra',
		'Show password': 'Mostrar contraseña',
		'Hide password': 'Ocultar contraseña',
		'Create Account': 'Crear cuenta',
		'Enter your Username': 'Ingresa tu nombre de usuario',
		'Enter your Password': 'Ingresa tu contraseña',
		'Confirm Password': 'Confirmar contraseña',
		'Please confirm your Password': 'Confirma tu contraseña',
		'Resend Code': 'Reenviar Código',
	},
};
I18n.putVocabularies(dict);
const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
	<Authenticator
		hideSignUp={true}
		formFields={formFields}
		components={components}
	>
		<App />
	</Authenticator>
);
