import React, { useEffect } from "react";
import TextArea from "antd/es/input/TextArea";
import { Button, Row, Select } from "antd";
import "./EditorCommonStyles.css";

export interface ResumeProps {
  resumen: string;
  titulos: Array<string>;
  titulo: string;
}

const Resume: React.FC<{
  resume: ResumeProps;
  onChange: () => void;
  onSave: (resume: ResumeProps) => void;
  onUndo: () => void;
}> = ({ resume, onChange, onUndo, onSave }) => {
  const [isEdited, setIsEdited] = React.useState(false);
  const [editedResume, setEditedResume] = React.useState<string>("");
  const [selectedTitle, setSelectedTitle] = React.useState<string>("");

  useEffect(() => {
    setEditedResume(resume.resumen.replaceAll("<br><br>", "\n"));
    setSelectedTitle(resume.titulo);
  }, [resume]);
  return (
    <>
      <Row justify={"start"}>
        <h4 className="label">Título: </h4>
        <Select
          options={resume.titulos.map((titulo) => ({
            label: titulo,
            value: titulo,
          }))}
          status={selectedTitle.length > 0 ? "" : "error"}
          value={selectedTitle}
          onChange={(value) => {
            setSelectedTitle(value);
            setIsEdited(true);
            onChange();
          }}
          style={{
            width: "100vh",
            textAlign: "start",
          }}
          placeholder="Seleccione un título..."
          optionFilterProp="label"
        />
      </Row>
      <h4 className="label" style={{ marginTop: "10px", marginBottom: "10px" }}>
        Resumen:{" "}
      </h4>
      <TextArea
        value={editedResume}
        defaultValue={editedResume}
        onChange={(e) => {
          setEditedResume(e.target.value);
          setIsEdited(true);
        }}
        autoSize={{ minRows: 10, maxRows: 15 }}
      />
      <Row justify={"end"}>
        <Button
          className="save-button"
          onClick={() => {
            onSave({
              titulos: resume.titulos,
              resumen: editedResume,
              titulo: selectedTitle,
            });
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Guardar
        </Button>
        <Button
          className="undo-button"
          onClick={() => {
            setEditedResume(resume.resumen);
            setSelectedTitle("");
            onUndo();
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Deshacer
        </Button>
      </Row>
    </>
  );
};
export default Resume;
