import React, { useEffect } from "react";
import { Button, Drawer, Input, Row, Space, Tree } from "antd";
import EditorActionPanel from "../EditorActionPanel";
import "./EditorCommonStyles.css";

export interface numbersInterface {
  categoria: string;
  edited?: boolean;
  deleted?: boolean;
  datos: {
    numero: string;
    descripcion: string;
    contexto_original: string;
    start: number;
    end: number;
    words: Array<any>;
    edited?: boolean;
    deleted?: boolean;
  }[];
}

const Numbers: React.FC<{
  numbers: Array<numbersInterface>;
  onSave: (numbers: Array<numbersInterface>) => void;
  onChange: () => void;
  onUndo: () => void;
}> = ({ numbers, onSave, onChange, onUndo }) => {
  const [isEdited, setIsEdited] = React.useState(false);
  const [editedNumbers, setEditedNumbers] = React.useState<numbersInterface[]>(
    []
  );
  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = React.useState(false);
  const [isNumberDrawerOpen, setIsNumberDrawerOpen] = React.useState(false);
  const [editingCategory, setEditingCategory] = React.useState<{
    index: number;
    categoria: string;
    newValue: string;
  }>();
  const [editingNumber, setEditingNumber] = React.useState<{
    index: number;
    subindex: number;
    descripcion: string;
    newDescription: string;
    numero: string;
    newNumber: string;
  }>();
  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);

  useEffect(() => {
    setEditedNumbers([...numbers]);
    setExpandedKeys(numbers.map((item, index) => `numbers-index-${index}`));
  }, [numbers]);

  const handleCategoryEdit = (index: number, categoria: string) => {
    setEditingCategory({ index, categoria, newValue: categoria });
    setIsCategoryDrawerOpen(true);
  };

  const handleCategoryEditConfirm = () => {
    if (editingCategory?.index == null) return;
    setEditedNumbers(
      editedNumbers.map((item, index) => {
        if (index === editingCategory?.index) {
          return {
            ...item,
            categoria: editingCategory?.newValue || item.categoria,
            edited: true,
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
    setIsCategoryDrawerOpen(false);
  };

  const handleNumberEdit = (
    index: number,
    subindex: number,
    numero: string,
    descripcion: string
  ) => {
    setEditingNumber({
      index,
      subindex,
      descripcion,
      newDescription: descripcion,
      numero,
      newNumber: numero,
    });
    setIsNumberDrawerOpen(true);
  };

  const handleNumberEditConfirm = () => {
    setEditedNumbers(
      editedNumbers.map((item, index) => {
        if (index === editingNumber?.index) {
          return {
            ...item,
            datos: item.datos.map((subitem, subindex) => {
              if (subindex === editingNumber?.subindex) {
                return {
                  ...subitem,
                  numero: editingNumber?.newNumber || subitem.numero,
                  descripcion:
                    editingNumber?.newDescription || subitem.descripcion,
                  edited: true,
                };
              }
              return subitem;
            }),
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
    setIsNumberDrawerOpen(false);
  };

  const handleCategoryDelete = (index: number) => {
    setEditedNumbers(
      editedNumbers.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            deleted: true,
            datos: item.datos.map((subitem) => ({ ...subitem, deleted: true })),
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
  };

  const handleNumberDelete = (categoryIndex: number, numberIndex: number) => {
    setEditedNumbers(
      editedNumbers.map((item, index) => {
        if (index === categoryIndex) {
          return {
            ...item,
            datos: item.datos.map((subitem, subindex) => {
              if (subindex === numberIndex) {
                return { ...subitem, deleted: true };
              }
              return subitem;
            }),
          };
        }
        return item;
      })
    );
    setIsEdited(true);
    onChange();
  };

  const handleCategoryUndo = (index: number) => {
    setEditedNumbers(
      editedNumbers.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            categoria: numbers[index].categoria,
            deleted: false,
            edited: false,
          };
        }
        return item;
      })
    );
  };

  const handleNumberUndo = (index: number, subindex: number) => {
    setEditedNumbers(
      editedNumbers.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            datos: item.datos.map((subitem, j) => {
              if (j === subindex) {
                return {
                  ...subitem,
                  numero: numbers[index].datos[subindex].numero,
                  descripcion: numbers[index].datos[subindex].descripcion,
                  deleted: false,
                  edited: false,
                };
              }
              return subitem;
            }),
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      <Tree
        expandedKeys={expandedKeys}
        onExpand={() => {
          setExpandedKeys([...expandedKeys]);
        }}
        defaultExpandParent={true}
        defaultExpandAll={true}
        style={{ textAlign: "left", width: "100%" }}
        treeData={editedNumbers.map((category, index) => ({
          key: `numbers-index-${index}`,
          title: (
            <div style={{ fontWeight: "bold" }}>
              <EditorActionPanel
                showUndo={category?.edited || category?.deleted}
                onEditAction={() => {
                  handleCategoryEdit(index, category.categoria);
                }}
                onDeleteAction={() => {
                  handleCategoryDelete(index);
                }}
                onUndoAction={() => {
                  handleCategoryUndo(index);
                }}
              />
              <span
                style={
                  category?.deleted
                    ? {
                        textDecorationLine: "line-through",
                        textDecorationColor: "red",
                        textDecorationStyle: "solid",
                      }
                    : {}
                }
              >
                {category.categoria}
              </span>
            </div>
          ),
          children: category.datos
            .filter((number) => number !== null)
            .map((number, subindex) => {
              if (number === null) {
                return {
                  key: `numbers-index-${index}-subindex-${subindex}`,
                  title: null,
                };
              }
              return {
                key: `numbers-index-${index}-subindex-${subindex}`,
                title: (
                  <div style={{ textAlign: "left" }}>
                    <EditorActionPanel
                      showUndo={number?.edited || number?.deleted}
                      onEditAction={() =>
                        handleNumberEdit(
                          index,
                          subindex,
                          number.numero,
                          number.descripcion
                        )
                      }
                      onDeleteAction={() => handleNumberDelete(index, subindex)}
                      onUndoAction={() => handleNumberUndo(index, subindex)}
                    />
                    <span
                      style={
                        number?.deleted
                          ? {
                              textDecorationLine: "line-through",
                              textDecorationColor: "red",
                              textDecorationStyle: "solid",
                            }
                          : {}
                      }
                    >
                      <span style={{ fontWeight: "bold", margin: "5px" }}>
                        {number.numero}
                      </span>
                      {number.descripcion}
                    </span>
                    <div style={{ width: "80%", color: "#666" }}>
                      <q>{number.contexto_original}</q>
                    </div>
                  </div>
                ),
              };
            }),
        }))}
      />
      <Row justify={"end"}>
        <Button
          className="save-button"
          onClick={() => {
            onSave(editedNumbers);
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Guardar
        </Button>
        {/* <Button
          className="undo-button"
          onClick={() => {
            setEditedNumbers([...numbers]);
            onUndo();
            setIsEdited(false);
          }}
          disabled={!isEdited}
        >
          Deshacer
        </Button> */}
      </Row>
      <Drawer
        title={`Editar categoría ${editingCategory?.categoria}`}
        placement={"bottom"}
        width={500}
        onClose={() => setIsCategoryDrawerOpen(false)}
        open={isCategoryDrawerOpen}
        extra={
          <Space>
            <Button onClick={() => setIsCategoryDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button type="primary" onClick={handleCategoryEditConfirm}>
              Guardar
            </Button>
          </Space>
        }
      >
        <Input
          type="text"
          defaultValue={editingCategory?.categoria || ""}
          value={editingCategory?.newValue || ""}
          onChange={(e) => {
            if (
              editingCategory?.index != null &&
              editingCategory?.categoria !== null
            )
              setEditingCategory({
                index: editingCategory?.index || 0,
                categoria: editingCategory?.categoria || "",
                newValue: e.target.value,
              });
          }}
        />
      </Drawer>
      <Drawer
        title={`Editar el dato de ${editingNumber?.descripcion}`}
        placement={"bottom"}
        width={500}
        onClose={() => setIsNumberDrawerOpen(false)}
        open={isNumberDrawerOpen}
        extra={
          <Space>
            <Button onClick={() => setIsNumberDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button type="primary" onClick={handleNumberEditConfirm}>
              Guardar
            </Button>
          </Space>
        }
      >
        <Input
          defaultValue={editingNumber?.numero || ""}
          value={editingNumber?.newNumber || ""}
          onChange={(e) => {
            setEditingNumber({
              index: editingNumber?.index || 0,
              subindex: editingNumber?.subindex || 0,
              descripcion: editingNumber?.descripcion || "",
              numero: editingNumber?.numero || "0",
              newNumber: e.target.value,
              newDescription: editingNumber?.newDescription || "",
            });
          }}
        />
        <Input
          type="text"
          defaultValue={editingNumber?.descripcion || ""}
          value={editingNumber?.newDescription || ""}
          onChange={(e) => {
            setEditingNumber({
              index: editingNumber?.index || 0,
              subindex: editingNumber?.subindex || 0,
              descripcion: editingNumber?.descripcion || "",
              numero: editingNumber?.numero || "0",
              newNumber: editingNumber?.newNumber || "0",
              newDescription: e.target.value,
            });
          }}
        />
      </Drawer>
    </>
  );
};

export default Numbers;
