import React from "react";
import Button from "../Components/Button";
import "./Styles/Home.css";
import { NavLink } from "react-router-dom";
import { Image } from "antd";

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <div className="HomeContent">
        <div className="HomeContainerImg">
          <Image width={300} src="/assets/logo-clarin.png" preview={false} />
        </div>
        <NavLink to="/upload">
          <Button label="Subir nuevo discurso" />
        </NavLink>
        <NavLink to="/list-audios">
          <Button label="Transcripciones Anteriores" />
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default Home;
