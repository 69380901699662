import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, message } from "antd";

const SnippetDrawer = (props: { articleId: string }) => {
  const [copyText, setCopyText] = useState("Copiar");

  useEffect(() => {
    setTimeout(() => {
      setCopyText("Copiar");
    }, 3000);
  }, [copyText]);

  const snippet = ` <script>
      if (!document.querySelector('script[src="${process.env.REACT_APP_WIDGET_ASSETS_URL}/js/ualter-sound.js"]')) {
          const script = document.createElement('script');
          script.src = '${process.env.REACT_APP_WIDGET_ASSETS_URL}/js/ualter-sound.js';
          document.head.appendChild(script);
      }
      if (!document.querySelector('link[href="${process.env.REACT_APP_WIDGET_ASSETS_URL}/css/ualter-sound.css"]')) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = '${process.env.REACT_APP_WIDGET_ASSETS_URL}/css/ualter-sound.css';
          document.head.appendChild(link);
      }
      if (!document.querySelector('link[href="${process.env.REACT_APP_CONTENT_FETCH_BASE_URL}/${props.articleId}"]')) {
          const prefetch = document.createElement('link');
          prefetch.rel = 'preload';
          prefetch.href = '${process.env.REACT_APP_CONTENT_FETCH_BASE_URL}/${props.articleId}';
          prefetch.as = 'fetch';
          document.head.appendChild(prefetch);
      }
  </script>
  <ualter-sound data-content-id="${props.articleId}"></ualter-sound>`;
  const handleCopy = () => {
    message.success("El código ha sido copiado al portapapeles");
    setCopyText("Copiado");
  };

  return (
    <div>
      <pre style={{ fontSize: "xx-small", backgroundColor: "#DDD" }}>
        {snippet}
      </pre>
      <CopyToClipboard text={snippet} onCopy={handleCopy}>
        <Button type="primary">{copyText}</Button>
      </CopyToClipboard>
    </div>
  );
};

export default SnippetDrawer;
